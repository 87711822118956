import React, {useState} from 'react';
import MenuIcon from "../../../assets/components/icons/MenuIcon";
import {Drawer} from "@mui/material";
import CloseIcon from "../../../assets/components/icons/CloseIcon";
import navData from "../../../data/layout/nav.json";
import {NavLink} from "react-router-dom";

const HeaderMobileMenu = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div onClick={() => setOpen(true)}>
                <MenuIcon/>
            </div>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className='menu_drawer'>
                    <div onClick={() => setOpen(false)} className='close_icon'>
                        <CloseIcon />
                    </div>
                    <nav>
                        {navData.map((item, idx) => (
                            <NavLink key={item.label + idx} exact activeClassName="selected" to={item.slug}>
                                <span>{item.label}</span>
                            </NavLink>
                        ))}
                    </nav>
                    <button>Sign In</button>
                </div>
            </Drawer>
        </>
    );
};

export default HeaderMobileMenu;
