import React from 'react';
import socialLinks from '../../../data/layout/socialLinks.json'

const SocialIcons = () => {
    const socialIcons = {
        TwitterIcon: <span className="icon-twitter-2-1"></span>,
        FbIcon: <span className="icon-facebook-5-1"></span>,
        InstaIcon: <span className="icon-Vector-1"></span>,
        TelegramIcon: <span className="icon-Group"></span>,
        YoutubeIcon: <span className="icon-Page-1"></span>,
        MailIcon: <span className="icon-1"></span>
    }
    return (
        <div className='social_icons'>
            {socialLinks.map((item, idx) => (
                <div key={item.href + idx} className='nth_icon'>
                    <a href={item.href} target='_blank'>
                        {socialIcons[item.icon]}
                    </a>
                    <div className='icon_back'>

                    </div>
                </div>

            ))}
        </div>
    );
};

export default SocialIcons;