import React from 'react';
import '../../home/banner/style.scss';
import './style.scss';
import BannerBackImg from "../../../assets/images/banner-back.png";
import BannerImg from "../../../assets/images/staking-banner.png";
import useWindowSize from "../../../utils/useWindowSize";

const Banner = () => {
    const {isDesktop} = useWindowSize();
    return (
        <div className='staking_banner home_banner'>
            <div className='banner_inner padding_global' style={{backgroundImage: `url(${BannerBackImg}`}}>
                <div className="banner_texts">
                    <p className="title">ABout Staking</p>
                    <p className='subtitle'>Increase your earnings while asleep!</p>
                    <p className="description">OrbiteX staking is an awesome and simple way to increase your savings
                        without completing additional actions on your side, as we will do it for you. Instead of leaving
                        your crypto assets sitting in your wallet, just stake them on our platform, earn rewards and
                        maximize your holdings.</p>
                    <div className='btns'>
                        <button className='btn_global'>Add Your Coin</button>
                        <button className='btn_global'>My Stakings</button>
                    </div>
                </div>
                <div className='banner_image'>
                    <img src={BannerImg} alt='Banner Image'/>
                </div>
            </div>

            {isDesktop && <div className='bottom_linear'></div>}
        </div>
    );
};

export default Banner;