import React from 'react';
import Banner from "../../components/staking/banner";

const Staking = () => {
    return (
        <div>
            <Banner />
        </div>

    );
};

export default Staking;