import AppRouter from "./Route";
import Layout from "./components/layout";
import './styles/globals.scss';
import './styles/_variables.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/icomoon/style.css';

function App() {
  return (
    <div className="App">
        <Layout>
            <AppRouter />
        </Layout>
    </div>
  );
}

export default App;
