import React from 'react';
import logoSrc from '../../../assets/images/logo-orbitex.png';

const HeaderLogo = () => {
    return (
        <div>
            <img src={logoSrc} alt='Orbitex' />
        </div>
    );
};

export default HeaderLogo;