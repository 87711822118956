import React from 'react';
import navData from "../../../data/layout/nav.json";
import {NavLink} from "react-router-dom";
import useWindowSize from "../../../utils/useWindowSize";
import HeaderMobileMenu from "./HeaderMobileMenu";

const HeaderMenu = () => {
    const {isDesktop} = useWindowSize();


    return (
        <>
            {isDesktop ? (
                <>
                    <nav>
                        {navData.map((item, idx) => (
                            <NavLink key={item.label + idx} to={item.slug}>
                                <span>{item.label}</span>
                            </NavLink>
                        ))}
                    </nav>
                    <button className='btn_global outlined'>Sign In</button>
                </>
            ) : (
                <HeaderMobileMenu/>
            )}

        </>
    );
};

export default HeaderMenu;
