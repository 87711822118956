import React, {lazy, Suspense} from 'react';
import {Routes, Route} from "react-router-dom";
import Staking from "./pages/staking";
const Home = lazy(() => import('./pages/home'));
const Market = lazy(() => import('./pages/market'));
const News = lazy(() => import('./pages/news'));

const AppRouter = () => {
    return (
        <Suspense fallback={<p> Loading...</p>}>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/market' element={<Market />} />
                <Route path='/news' element={<News />} />
                <Route path='/staking' element={<Staking />} />
            </Routes>
        </Suspense>

    );
};

export default AppRouter;
