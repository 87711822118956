import {useEffect, useState} from "react";


export default function useWindowSize() {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleReSize = () => {
            if(window.innerWidth <= 1024) {
                setIsTablet(true)
                setIsDesktop(false)
            }else {
                setIsTablet(false)
                setIsDesktop(true)
            }
            if (window.innerWidth <= 768) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
        window.addEventListener('resize', handleReSize);
        handleReSize();
        return () => window.removeEventListener('resize', handleReSize);

    }, [])

    return {isDesktop, isTablet, isMobile};

}
