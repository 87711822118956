export default function CloseIcon() {
    return (
        <svg
            width={28}
            height={28}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.1255 0.895834C26.313 0.0833338 25.0005 0.0833338 24.188 0.895834L14.0005 11.0625L3.81299 0.875C3.00049 0.0625 1.68799 0.0625 0.875488 0.875C0.0629883 1.6875 0.0629883 3 0.875488 3.8125L11.063 14L0.875488 24.1875C0.0629883 25 0.0629883 26.3125 0.875488 27.125C1.68799 27.9375 3.00049 27.9375 3.81299 27.125L14.0005 16.9375L24.188 27.125C25.0005 27.9375 26.313 27.9375 27.1255 27.125C27.938 26.3125 27.938 25 27.1255 24.1875L16.938 14L27.1255 3.8125C27.9171 3.02083 27.9171 1.6875 27.1255 0.895834Z"
                fill="#6BD6F0"
            />
        </svg>

    )
};
