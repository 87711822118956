import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import './style.scss';
import '../../../styles/_variables.scss'
import HeaderMenu from "./HeaderMenu";

const Header = () => {
    const headerRef = useRef();
    const stickyHeaderHandler = () => {
        if (window.scrollY > 200) {
            headerRef.current.classList.add('fixed');
        } else {
            headerRef.current.classList.remove('fixed');
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', stickyHeaderHandler, true);

        return () => {
            window.removeEventListener('scroll', stickyHeaderHandler, true);
        }
    }, [])

    return (
        <header className='padding_global' ref={headerRef}>
            <Link to="/">
                <HeaderLogo/>
            </Link>
            <HeaderMenu/>
        </header>
    );
};

export default Header;
