import React from 'react';
import HeaderLogo from "../header/HeaderLogo";
import TwitterIcon from "../../../assets/components/icons/socialcons/TwitterIcon";
import FbIcon from "../../../assets/components/icons/socialcons/FbIcon";
import InstaIcon from "../../../assets/components/icons/socialcons/InstaIcon";
import TelegramIcon from "../../../assets/components/icons/socialcons/TelegramIcon";
import YoutubeIcon from "../../../assets/components/icons/socialcons/YoutubeIcon";
import MailIcon from "../../../assets/components/icons/socialcons/MailIcon";
import useWindowSize from "../../../utils/useWindowSize";
import './style.scss';
import SocialIcons from "./SocialIcons";

const Footer = () => {
    const {isDesktop} = useWindowSize();



    return (
        <footer className='padding_global'>
            <div className='footer_content'>
                <div className='contact_block'>
                    <div className='logo'>
                        <HeaderLogo/>
                    </div>


                    <div className='contacts'>
                        {isDesktop ? <SocialIcons /> : null}
                        <p>+374 (93) 123 456</p>
                        <p>example@gmail.com</p>
                        <p>New York str 15</p>
                    </div>
                </div>
                {!isDesktop ? <SocialIcons />: null}
            </div>


            <div className='bottom_block'>
                <span>All rights reserved </span>
                <span className='line'></span>
                <span>2022 Orbitex.org</span>
            </div>
        </footer>
    );
};

export default Footer;
